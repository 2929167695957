<template>
    <b-card class="w-100 mb-3 custom-scroll qcard">
        <b-container fluid class="p-0">
            <b-table
                :items="tableData"
                :fields="fields"
                striped
                hover
                responsive
                sticky-header="400px"
                class="diopters-table"
            >
                <template #cell(diopterSteps)="data">
                    <span v-html="data.item.diopterSteps.join(',<br />')"></span>
                </template>
                <template #cell(sphericDiopters)="data">
                    <span>{{ data.item.sphericDiopters.join(', ') }}</span>
                </template>
                <template #cell(cylinderDiopters)="data">
                    <span>{{ data.item.cylinderDiopters.join(', ') }}</span>
                </template>
            </b-table>
        </b-container>
    </b-card>
</template>

<script>
export default {
    name: 'ToricTable',
    props: {
        validations: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            fields: [
                {key: 'salesCategory', label: this.$t('qGuide_salesCategory')},
                {key: 'itemDescription', label: this.$t('qGuide_itemDescription')},
                {
                    key: 'diopterSteps',
                    label: this.$t('qGuide_diopterSteps'),
                    thStyle: {width: '20%'},
                    tdStyle: {width: '20%'},
                },
                {key: 'sphericDiopters', label: this.$t('qGuide_sphericDiopters')},
                {key: 'cylinderDiopters', label: this.$t('qGuide_cylinderDiopters')},
            ],
            tableData: this.validations.map((validation) => {
                const diopterSteps = [
                    `${this.$t('qGuide_fromSphere')} ${validation.beginSphereDiopter} ${this.$t(
                        'qGuide_to'
                    )} ${validation.endSphereDiopter} ${this.$t('qGuide_inHalfDioptres')}`,
                    `${this.$t('qGuide_fromCylinder')} ${validation.beginCylinderDiopter} ${this.$t(
                        'qGuide_to'
                    )} ${validation.endCylinderDiopter} ${this.$t('qGuide_inHalfDioptres')}`,
                ];
                return {
                    salesCategory: validation.salesCategory,
                    itemDescription: validation.description,
                    diopterSteps: diopterSteps,
                    sphericDiopters: validation.sphereDiopters,
                    cylinderDiopters: validation.cylinderDiopters,
                };
            }),
        };
    },
};
</script>
